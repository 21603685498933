* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Added here as mui theme takes some time to load */
body {
  font-family: 'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  min-height: 100vh;
  overflow: auto;
}

body {
  display: flex;
  width: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ddd;
}

.MuiTooltip-tooltip {
  font-size: 12px !important;
}

.container {
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
}

.big_container {
  margin: 0 auto;
  width: 100%;
  max-width: 2200px;
}

@media (max-width: 2200px) {
  .big_container {
    margin: 0 auto;
    width: 100%;
    max-width: 95%;
  }
}

.big_container {
  margin: 0 auto;
  width: 100%;
  max-width: 2200px;
}

.table_big_container {
  margin: 0 auto;
  width: 100%;
  max-width: 2200px;
}

@media (max-width: 2300px) {
  .table_big_container {
    margin: 0 auto;
    width: 100%;
    max-width: 1650px;
  }
}

@media (max-width: 1760px) {
  .table_big_container {
    margin: 0 auto;
    width: 100%;
    max-width: 1200px;
  }
}

.container-data-viewer {
  margin: 0 auto;
  width: 100%;
  max-width: 100vw;
}

input:active,
input:focus,
textarea:active,
textarea:focus,
button:active,
button:focus {
  outline: none;
}

button {
  cursor: pointer;
}

a,
a:active,
a:visited,
a:focus {
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
}

.badge {
  display: inline-flex;
  align-items: center;
  padding: 3px 10px;
  border-radius: 20px;
  text-decoration: none;
}

.link-like {
  color: #0066cc;
  cursor: pointer;
}

.badge i {
  display: inline-block;
  margin-right: 5px;
}

.badge.grey {
  background: #f0f0f0;
  color: #757575;
}

.badge.blue {
  background: #d1e3ed;
  color: #0083cb;
}

.badge.blue-text {
  color: #0066cc;
}

.badge.blue-white {
  background: #0066cc;
  color: #fff;
}

.badge.red {
  background: #f54444;
  color: #fff;
}

.badge.green {
  background: #0aa227;
  color: #fff;
}

.badge.wide {
  padding: 5px 20px;
}

.badge.high {
  padding-top: 10px;
  padding-bottom: 10px;
}

.form-field {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.form-field-label {
  display: block;
  margin-bottom: 7px;
  margin-right: 15px;
}

.form-field-label > .required {
  margin-left: 5px;
  color: red;
  font-weight: bolder;
}

.form-field-input,
.form-field-chips-input,
.form-field-textarea {
  display: inline-block;
  width: 100%;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid #ddd;
  font-size: 16px;
  transition: border-color 0.2s ease-in;
  resize: none;
}

.form-field-input:focus,
.form-field-chips-input:focus,
.form-field-textarea:focus {
  border-color: #999;
  transition: border-color 0.2s ease-in;
}

.form-field-chips-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 5px;
  min-height: 54px;
}

.form-field-chips-input .form-field-input {
  border: none;
  min-width: 200px;
  flex: 1;
  padding: 0;
  margin-top: 5px;
}

.form-field-input-chip {
  margin-right: 10px;
  margin-top: 5px;
  font-weight: bold;
}

.form-field-input-chip:last-child {
  margin-bottom: 0;
}

.form-field-textarea {
  height: 150px;
  resize: none;
}

.form-field-select {
  padding: 10px !important;
  border-radius: 3px !important;
  border: 1px solid #ddd !important;
  font-size: 16px !important;
  width: 100% !important;
  transition: border-color 0.2s ease-in;
}

.form-field-select-dropdown {
  padding: 10px !important;
  border-radius: 3px !important;
  border: 1px solid #ddd !important;
  font-size: 16px !important;
  width: 100% !important;
  height: 100% !important;
  transition: border-color 0.2s ease-in;
}

.form-field-select-dropdown input {
  display: none !important;
}

.form-field-select:focus-within {
  outline: none !important;
  box-shadow: none !important;
  border-color: #999 !important;
  transition: border-color 0.2s ease-in;
}

.form-field-select .react-dropdown-select-input {
  font-size: 14px !important;
}

.react-dropdown-select-dropdown {
  width: 100% !important;
}

.form-actions {
  text-align: right;
  margin-bottom: 20px;
}

.form-action {
  display: inline-block;
  padding: 10px 17px;
  background: transparent;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  margin-left: 10px;
}

.form-action:disabled {
  border: 1px solid #f0f0f0 !important;
  background: #f0f0f0 !important;
  color: #ccc !important;
  cursor: not-allowed;
}

.form-action:first-child {
  margin-left: 0;
}

.form-action {
  border: 1px solid #ddd;
}

.form-action:focus {
  border: 1px solid #ddd;
  box-shadow: 0 0 1px 1px #ddd;
}

.form-action.success {
  color: #fff;
  border: 1px solid #0066cc;
  background: #0066cc;
}

.form-action.success:focus {
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px #0066cc;
}

.form-action.warn {
  color: #fff;
  border: 1px solid lightcoral;
  background: lightcoral;
}

.form-action.warn:focus {
  border: 1px solid #fff;
  box-shadow: 0 0 1px 1px lightcoral;
}

.errors {
  color: lightcoral;
  padding: 20px 0;
  line-height: 1.7rem;
  text-align: center;
}

.errorsMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 21px;
  height: 100%;
}

.form-success-message {
  text-align: center;
  color: #0066cc;
  font-weight: bold;
}

.active-sublink {
  background: #0066cc;
  transition: all 0.3s ease-in-out;
  color: #fff !important;
}

.active-sublink img {
  display: none !important;
}

.backwards-link i {
  display: inline-block;
  margin-right: 10px;
}

.react-date-picker__calendar {
  z-index: 3 !important;
}

.pointer:hover {
  cursor: pointer;
}

.MuiListItem-root.Mui-selected {
  /*background-color: #e6f7ff !important;*/
  background-color: rgb(230, 244, 255) !important;
  color: #1890ff !important;
  border-right: 2px solid rgb(22, 119, 255) !important;
  /*border-right: 2px solid #0066cc;*/
}

.MuiListItem-root.Mui-selected svg {
  color: #1890ff !important;
}

/*.MuiListItem-root.Mui-selected span {*/
/*  color: #1890ff !important;*/
/*}*/

.css-h0q0iv-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #1890ff !important;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root {
  color: #262626 !important;
}

.MuiListItem-button:hover {
  /*background-color: #e6f7ff !important;*/
  background-color: rgb(230, 244, 255) !important;
  color: #1890ff !important;
  /*background-color: rgba(0, 0, 0, 0.04);*/
}

.MuiListItem-button {
  -webkit-tap-highlight-color: transparent !important;
  background-color: transparent !important;
  outline: 0px !important;
  border: 0px !important;
  margin: 0px 0px 4px !important;
  border-radius: 0px !important;
  cursor: pointer !important;
  user-select: none !important;
  vertical-align: middle !important;
  appearance: none !important;
  color: inherit !important;
  display: flex !important;
  flex-grow: 1 !important;
  justify-content: flex-start !important;
  align-items: center !important;
  position: relative !important;
  text-decoration: none !important;
  min-width: 0px !important;
  box-sizing: border-box !important;
  text-align: left !important;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  padding: 8px 16px 8px 23px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  font-weight: 400 !important;
  font-size: 0.875rem !important;
  line-height: 1.25 !important;
  font-family: 'Public Sans', sans-serif !important;
  color: rgb(38, 38, 38);
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root:hover {
  background-color: rgba(230, 244, 255, 0.376) !important;
  color: #1890ff !important;
}

.css-h0q0iv-MuiButtonBase-root-MuiTab-root:hover {
  background-color: rgba(230, 244, 255, 0.376) !important;
  color: #1890ff !important;
}

/*.MuiTypography-body1 {*/
/*  margin: 0px !important;*/
/*  font-weight: 400 !important;*/
/*  font-size: 0.875rem !important;*/
/*  line-height: 1.57 !important;*/
/*  font-family: "Public Sans", sans-serif !important;*/
/*  color: rgb(38, 38, 38) !important;*/
/*}*/

.MuiCollapse-wrapper {
  display: flex;
  /* max-height: 500px; */
  overflow: auto;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  background-color: #ffffff !important;
}

/*.MuiListItemIcon-root {*/
/*  color: unset !important;*/
/*}*/

.customMuiIconButton {
  margin-left: -10px !important;
}

.MuiTab-wrapper {
  flex-direction: row !important;
}

.makeStyles-content {
  background-color: #f0f0f0 !important;
}

.chart-tooltip {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px;
  border-radius: 6px;
  width: 300px !important;
  background-color: #fff;
  font-size: 13px;
  outline: 3px solid #d8d8d8;
}

.chart-comment {
  width: 100%;
  overflow-wrap: break-word;
  white-space: pre-line;
  hyphens: auto;
}

.title {
  font-weight: 600;
}

/* QUILL TEXT EDITOR */

.editor-container {
  height: 90%;
  display: flex;
  flex-direction: column;
  border: none;
  border-radius: 6px;
  background-color: white;
  border: 1px solid lightgray;
}

#editor {
  order: 1;
  min-height: 300px;
  border-bottom: none;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

#toolbar {
  width: fit-content;
  order: 2;
  border-radius: 2px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: none;
  padding: 8px;
  background-color: white;
}
.ql-editor {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.ql-editor p {
  font-size: 16px;
}

.ql-formats {
  margin-right: 8px;
}

button.ql-active {
  background-color: #d9d9d9;
}

.ql-toolbar button:hover {
  background-color: #e8e8e8;
}

.ql-container.ql-container.ql-snow {
  border: none;
  border-radius: 6px;
}

.ql-syntax {
  background-color: #c3c3c3;
  border-radius: 6px;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.ql-snow .ql-editor pre.ql-syntax {
  background-color: #efefef !important;
  color: black !important;
  border-radius: 6px;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.quill_render_container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.apexcharts-xaxistooltip-bottom {
  display: none;
}
