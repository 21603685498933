html {
  line-height: 1.2;
  -webkit-text-size-adjust: 100%;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  display: block;
}

.app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}

.app > .content {
  flex-grow: 1;
  display: flex;
  padding-top: 75px;
}

.mobileModalMessage {
  min-width: 530px;
  min-height: 350px;
}

.modalFont {
  font-size: 18px;
}

@media (max-width: 500px) {
  .app > .content {
    padding-top: 5px;
  }
  .mobileModalMessage {
    min-width: 200px;
  }
  .modalMessage {
    width: 100% !important;
  }
  .modal-content {
    width: 100% !important;
  }
  .modalFont {
    font-size: 12px;
  }
}

[contenteditable]:focus {
  outline: 0 solid transparent;
}

.app-top-tooltip-wrapper {
  position: absolute;
  top: -150%;
  left: 50%;
  overflow: auto;
  transform: translateX(-50%);
  z-index: 1000;
  transition: top 0.5s ease-in-out;
  visibility: hidden;
}

.app-warning {
  max-width: 300px;
  max-height: 200px;
  color: lightcoral;
  padding: 10px 30px;
  background: #fff;
  border: 1px solid lightcoral;
  border-radius: 5px;
  box-shadow: 0 0 3px lightcoral;
  font-weight: bold;
}

.app-notification {
  max-width: 400px;
  max-height: 400px;
  color: #909098;
  padding: 20px 20px;
  background: #fff;
  border: 1px solid #0066cc;
  border-radius: 5px;
  box-shadow: inset 0 0 3px 0 #0066cc;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-notification-inner {
  width: 80%;
}

.app-notification-inner > p {
  margin: 1rem 0;
}

.app-notification > p {
  margin-bottom: 15px;
}

.app-top-tooltip-wrapper.shown {
  top: 100px;
  transition: top 0.5s ease-in-out;
  visibility: visible;
}

@media screen and (max-width: 1530px) {
  .with-sidebar {
    padding-right: 60px;
  }
}

.app-loading-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  /*display: flex;*/
  /*justify-content: center;*/
}

.app-loading-wrapper-not-width {
  position: relative;
}

.app-loading-wrapper-img {
  display: flex;
  justify-content: center;
}

.app-loading-overlay {
  position: absolute;
  display: flex;
  z-index: 15;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(
    circle,
    #909098e0 2%,
    rgba(254, 249, 247, 0.02) 35%
  );
}

.app-loading-overlay > span {
  font-weight: bolder;
  color: white;
}

.app-indicator-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: limegreen;
  position: absolute;
}

.app-indicator-dot.top {
  right: 0;
  top: 0;
}

.app-indicator-dot.bottom {
  right: 0;
  bottom: 0;
}

.app-indicator-dot.red {
  background: rgba(170, 0, 0, 0.85);
}

.app-indicator-dot.grey {
  background: #999;
}

.transition-enter {
  opacity: 0.01;
}

.transition-enter.transition-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.transition-leave {
  opacity: 1;
}

.app-error-message {
  color: #ff0000;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0;
  width: 100%;
  transform: translateY(-15px);
  opacity: 0;
  animation: fade 150ms ease-out;
  animation-delay: 50ms;
  animation-fill-mode: forwards;
  will-change: opacity, transform;
  max-height: 150px;
  position: relative;
  z-index: 10;
}

.app-error-message svg {
  margin-right: 10px;
}

.app-reset-button {
  height: 70px;
  width: 60px;
  border: 0;
  cursor: pointer;
  background: transparent;
}

.app-reset-button > svg {
  height: 100%;
  background-image: radial-gradient(
    circle,
    rgba(0, 37, 152, 0.88) 28%,
    transparent 0%
  );
}

.MuiButton-containedPrimary {
  background-color: #1976d2 !important;
  font-weight: 500 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
}

.MuiButton-containedPrimary:hover {
  background-color: #1565c0 !important;
}

.MuiButton-outlinedPrimary {
  color: #1976d2 !important;
  border: 2px solid rgba(25, 118, 210, 0.5) !important;
  font-weight: 600 !important;
  font-size: 0.875rem !important;
  line-height: 1.75 !important;
}

.MuiButton-outlinedPrimary:hover {
  text-decoration: none;
  background-color: rgba(25, 118, 210, 0.04);
  border: 2px solid #1976d2;
}

.app-switch-button {
  font-size: 14px;
  font-weight: bold;
  /*margin-left: 10px;*/
  cursor: pointer;
  white-space: nowrap;
  margin-bottom: 5px;
  position: relative;
  padding: 7px 20px;
  border-radius: 30px;
  color: #0066cc;
  background-color: inherit;
  text-decoration: none;
  border: none;
}

.app-switch-button--inline-block {
  display: inline-block;
}

.app-switch-button-active {
  background-color: #0066cc;
  transition: all 0.3s ease-in-out;
  color: #fff !important;
}

.transition-leave.transition-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.link {
  color: #0066cc;
  text-decoration: none;
  cursor: pointer;
}

.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.register-overlay-message {
  position: relative;
  width: 600px;
  height: 200px;
  background: #fff;
  padding: 20px 20px 20px;
  display: flex;
  flex-direction: column;
  border: 5px solid #9eb6ce;
  font-size: 17px;
  font-weight: bolder;
}

.register-overlay-message > div {
  justify-content: space-evenly;
}

.register-overlay-message > article {
  margin-bottom: 15px;
}

.register-overlay-message * button {
  background-color: aliceblue;
  font-size: 17px;
  font-weight: bolder;
  padding: 10px;
  border: 1px solid #9eb6ce;
}

.coalitionCalendarEventsButton {
  padding: 4px 10px;
  border: 2px solid #0066cc;
  border-radius: 10px;
  color: #0066cc;
  font-size: 21px;
  margin-left: 10px;
  background-color: #ffffff;
}

.displayColumnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.coalitionCalendarEventsButton:hover {
  cursor: pointer;
}

.coalitionCalendarEventsButton:disabled {
  background-color: lightgray;
}

.membersContainer {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    width: 100%;
    padding: 10px;

    div {
      word-wrap: break-word;
      font-weight: 600;
      width: 20%;
      text-align: center;
    }
  }

  &__member_header {
    display: flex;
    width: 100%;
    margin-top: 10px;
    //padding: 10px;
    div {
      //display: flex;
      border-right: 2px solid #ddd;
      background-color: #0066cc;
      color: white;
      padding: 10px;
      //position: sticky;
      top: 0;
      z-index: 10;
      word-wrap: break-word;
      font-weight: 600;
      width: 10%;
      text-align: center;
    }
  }
}

.header-logo-container__wrapper {
  display: flex;
  align-items: center;
}

@keyframes fade {
  from {
    opacity: 0;
    transform: scale3D(0.95, 0.95, 0.95);
  }
  to {
    opacity: 1;
    transform: scale3D(1, 1, 1);
  }
}
